<template>
  <DynamicView>
    <TableView
      :heading="$t(`pages.accountManagement`)"
      :description="subHeading"
      v-bind="{ buttons, tabs }"
    />
    <CreateUserDialog v-model="createUserDialog" @finish="userCreated" />
    <CreateOrganisationDialog
      v-model="createOrganisationDialog"
      @finish="orgCreated"
    />
  </DynamicView>
</template>

<script>
import { user } from '@/helpers'
import { EUserRole } from '@/enums'
import { DynamicView, TableView } from '@/components/layout'
import {
  EditUserDialog as CreateUserDialog,
  EditOrganisationDialog as CreateOrganisationDialog,
} from '@/components/dialog'

export default {
  components: {
    TableView,
    DynamicView,
    CreateUserDialog,
    CreateOrganisationDialog,
  },
  data() {
    return {
      createUserDialog: false,
      createOrganisationDialog: false,
      newUser: {
        value: null, // nested to enable reactive injection
      },
      newOrg: {
        value: null,
      },
    }
  },
  provide() {
    return {
      newUser: this.newUser,
      newOrg: this.newOrg,
    }
  },
  computed: {
    subHeading() {
      const rights = this.canManageOrganisations() ? 'full' : 'userAdmin'
      return this.$t(`accountManagement.${rights}Rights`)
    },
    buttons() {
      const entries = []

      if (this.canManageOrganisations()) {
        entries.push({
          label: this.$t('accountManagement.createOrganisation'),
          onClick: this.openCreateOrganisationDialog,
        })
      }

      entries.push({
        label: this.$t('accountManagement.createUser'),
        onClick: this.openCreateUserDialog,
      })

      return entries
    },
    tabs() {
      const entries = []

      if (this.canManageOrganisations()) {
        entries.push({
          routeName: 'organisationManagement',
          label: this.$t('accountManagement.organisations-tab'),
        })
      }

      entries.push({
        routeName: 'userManagement',
        label: this.$t('accountManagement.users-tab'),
      })

      return entries
    },
  },
  methods: {
    canManageOrganisations() {
      return (
        user.hasPermission([EUserRole.AccountAdministrator]) ||
        (this.$store.getters['auth/organisationIsFinancePartner'] &&
          user.hasPermission([EUserRole.AccountManager]))
      )
    },
    openCreateUserDialog() {
      this.createUserDialog = true
    },
    openCreateOrganisationDialog() {
      this.createOrganisationDialog = true
    },
    userCreated(user) {
      this.newUser.value = user
    },
    orgCreated(org) {
      this.newOrg.value = org
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'home' && !to.redirectedFrom) {
        if (to.name === 'userManagement') {
          vm.openCreateUserDialog()
        } else if (to.name === 'organisationManagement') {
          vm.openCreateOrganisationDialog()
        }
      }
    })
  },
}
</script>
